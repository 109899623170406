import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import { awsExports } from "../../aws-exports";
import { getProfile } from "../../shared/api/searchFormData";
import Storage from "../../shared/utils/Storage";
import { StorageKeys } from "../../constants/storage-keys";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography
} from "@mui/material";
import appRoutes from "../../constants/appRoutes";
import { enqueueSnackbar } from "notistack";
import { AppContext } from "../../shared/context/auth-context";

Amplify.configure(awsExports);

const DemoLogin = () => {
  const { updateProfileData, currentUser } = useContext(AppContext);
  const { Demo } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const service = Storage.getItem(StorageKeys.serviceLocalStorage);

  useEffect(
    () => {
      const autoLogin = async () => {
        try {
          const user = await Auth.signIn("9059588686");
          const otp = user.challengeParam.passCode;
          await Auth.sendCustomChallengeAnswer(user, otp);
          const { signInUserSession } = await Auth.currentAuthenticatedUser();

          await getProfile(signInUserSession?.idToken?.jwtToken).then((res) => {
            updateProfileData(res.data);
            Storage.setItem(StorageKeys.DEMO_LOGIN, Boolean(Demo));
            if (!service) {
              navigate(appRoutes.dashboard);
              setIsLoading(false);
            }
          });
        } catch (error) {
          console.error("Error signing in: ", error);
          setIsLoading(false);
          enqueueSnackbar("Error signing in, Try again later", {
            variant: "error"
          });
        }
      };

      if (Boolean(currentUser)) {
        setIsLoading(false);
        navigate(appRoutes.dashboard);
      } else {
        autoLogin();
      }
    }, // eslint-disable-next-line
    [currentUser]
  );

  return (
    <Grid
      container
      sx={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"center"}
          maxHeight={"xs"}
          maxWidth={"xs"}
          borderRadius={"8px"}
          sx={{
            background: "#fff",
            boxShadow: "0px 8px 24px 0px #1515161A",
            padding: "26px"
          }}
        >
          <CircularProgress sx={{ color: "#2773FF" }} />
          <Typography fontSize={18} fontWeight={700} color={"#2773FF"}>
            {"Please wait. Your Demo Account is Loading..."}
          </Typography>
        </Box>
      </Backdrop>
    </Grid>
  );
};

export default DemoLogin;
