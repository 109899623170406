import { useEffect, useState } from "react";
import { StorageKeys } from "../../constants/storage-keys";
import Storage from "../../shared/utils/Storage";

export const useDemoLogin = () => {
  const [demoLogin, setDemoLogin] = useState(null);
  const demoUser = Storage.getItem(StorageKeys.DEMO_LOGIN);

  useEffect(() => {
    setDemoLogin(demoUser);
  }, [demoUser]);

  return { demoLogin };
};
