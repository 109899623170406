import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Badge,
  Box,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ThemeProvider,
  Typography
} from "@mui/material";
import {
  Close,
  ExpandLess,
  ExpandMore,
  Menu as MenuIcon,
  Person
} from "@mui/icons-material";
import { DesktopSocialBar, MobileSocialBar } from "./SocialBar";
import { useLocation, useNavigate } from "react-router-dom";
import {
  NotificationsOutlined,
  ShoppingCartOutlined
} from "@mui/icons-material";
import {
  StyledMobileDrawer,
  StyledMobileIconButton,
  StyledToolBar,
  headerTheme
} from "../theme/headerTheme";
import { labels } from "../../constants";
import { useContext, useEffect, useState } from "react";

import { AppContext } from "../context/auth-context";
import AppLogo from "../../assets/Warex.svg";
import MobileAppLogo from "../../assets/MobileLogo.webp";
import Cart from "../../customer/components/Cart";
import Login from "./Login";
import Notifications from "../../customer/components/Notifications";
import appRoutes from "../../constants/appRoutes";
import { drawerWidth } from "../theme/drawerTheme";
import styled from "@emotion/styled";
import { useCustomDialog } from "../customDialog";
import { useDrawer } from "../customSideDrawer";
import { useFetchNotifications } from "../../query-hooks/Notifications/useFetchNotifications";
import useInViewSections from "../hooks/useInViewSections";
import { scroller } from "react-scroll";
import { StorageKeys } from "../../constants/storage-keys";
import Storage from "../utils/Storage";
import MoreMenu from "./MoreMenu";
import SuccessfulDialog from "../UiElements/SuccessfulDialog";

const countPropertiesWithValues = (obj) => {
  let count = 0;
  for (const key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      obj[key] !== null &&
      obj[key] !== undefined &&
      obj[key] !== ""
    ) {
      count++;
    }
  }
  return count;
};

const menu = [
  {
    label: "Pricing",
    to: appRoutes.pricing
  },
  {
    label: "Industries",
    href: "industries"
  },
  {
    label: "Partner",
    to: process.env.REACT_APP_PARTNER_REDIRECT_URL
  },
  {
    label: "Blog",
    href: "blog"
  },
  {
    label: "About Us",
    href: "about-us"
  },
  {
    label: "Contact Us",
    href: "contact-us"
  }
];

const productMenu = [
  {
    label: "Simple ERP",
    to: appRoutes.erp
  },
  {
    label: "Distribution as a Service",
    to: appRoutes.marketplace
  },
  {
    label: "E-waybills",
    to: appRoutes.ewaybill
  },
  {
    label: "Credit",
    to: appRoutes.credit
  }
];

const StyledPrimaryText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isActive"
})(({ theme, isActive }) => ({
  color: isActive ? "#2773FF" : theme.palette.text.primary,
  fontSize: 16,
  fontFamily: "Inter",
  lineHeight: "20px",
  fontWeight: isActive ? 500 : 400
}));

const StyledListItemText = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: "19px",
  color: "#FFFFFF",
  textDecoration: "none"
}));

const StyledListSubItemText = styled(Typography)(() => ({
  fontWeight: 300,
  fontSize: 14,
  lineHeight: "17px",
  color: "#C7CED4",
  textDecoration: "none",
  letterSpacing: "0.01em"
}));

const StyledLoginText = styled(Typography)(({ theme, isActive }) => ({
  color: isActive ? "#2773FF" : theme.palette.text.primary,
  fontSize: 14,
  fontFamily: "Inter",
  lineHeight: "20px",
  fontWeight: 600
}));

const MobileMenuList = ({ onClose, logout, login, isValid, onMenuClick }) => {
  return (
    <Grid container spacing={9}>
      <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
        <StyledMobileIconButton onClick={onClose}>
          <Close sx={{ color: "white" }} />
        </StyledMobileIconButton>
      </Grid>
      <Grid item xs={12} container spacing={3}>
        <Grid item xs={12}>
          <Accordion
            sx={{
              boxShadow: "none",
              border: "none",
              "&:before": { display: "none" },
              backgroundColor: "#0F2744"
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMore sx={{ fontSize: 22, color: "#FFFFFF", ml: 1 }} />
              }
              sx={{
                backgroundColor: "#0F2744",
                padding: 0,
                border: "none",
                position: "unset",
                minHeight: 0,
                display: "flex",
                justifyContent: "left",
                "& .MuiAccordionSummary-content": {
                  margin: 0,
                  flexGrow: 0
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: 0,
                  flexGrow: 0
                }
              }}
            >
              <StyledListItemText>Product</StyledListItemText>{" "}
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: "#0F2744",
                border: "none",
                p: `0px 0px 0px 16px`,
                mb: 0
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2
                }}
              >
                {productMenu.map((item, index) => (
                  <StyledListSubItemText
                    onClick={() => {
                      onMenuClick(item);
                      onClose();
                    }}
                  >
                    {item.label}
                  </StyledListSubItemText>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
        {menu.map((item, index) => (
          <Grid item xs={12} key={index}>
            <StyledListItemText
              onClick={() => {
                onMenuClick(item);
                onClose();
              }}
              sx={{
                cursor: "pointer"
              }}
            >
              {item.label}
            </StyledListItemText>
          </Grid>
        ))}
        <Grid item xs={12} mt={"138px"}>
          {isValid ? (
            <StyledListItemText
              component={"div"}
              onClick={() => {
                onClose();
                logout();
              }}
            >
              Logout
            </StyledListItemText>
          ) : (
            <StyledListItemText
              component={"div"}
              onClick={() => {
                onClose();
                login();
              }}
            >
              Login
            </StyledListItemText>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const Header = ({ home = false }) => {
  const sectionIds = ["industries", "blog", "about-us", "contact-us"];
  const activeSection = useInViewSections(sectionIds);
  const [activeItem, setActiveItem] = useState(null);

  const { showDrawer, hideDrawer } = useDrawer();
  const { showDialog, hideDialog } = useCustomDialog();

  const searchFormParams = Storage.getItem(StorageKeys.SEARCH_FORM_PARAMS);

  const [mobileDrawer, setMobileDrawer] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorProductMenu, setAnchorProductMenu] = useState(null);
  const {
    currentUser,
    profileData,
    demoLogin,
    logout,
    // setCrumbStack,
    onRemoveFromCart,
    handleTabChange,
    cart
  } = useContext(AppContext);

  const { data } = useFetchNotifications();

  const navigate = useNavigate();
  const location = useLocation();

  const isRoot = [
    "/",
    appRoutes.faqs,
    appRoutes.pricing,
    appRoutes.saas,
    appRoutes.marketplace,
    appRoutes.ewaybill,
    appRoutes.credit,
    appRoutes.erp,
    appRoutes.inventoryMangement,
    appRoutes.packersAndMovers.hyderabad,
    appRoutes.packersAndMovers.bengaluru
  ].includes(location.pathname);

  const isValid = Boolean(currentUser);

  const settings = isValid ? ["Profile", "Logout"] : ["Login"];

  const onLogoutClick = () => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to log out?"}
          btn1Text={"Cancel"}
          btn2Text={"Yes, confirm"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => {
            logout();
            navigate("/");
            hideDialog();
          }}
        />
      )
    });
  };

  useEffect(
    () => {
      if (Boolean(activeSection)) {
        // Update active menu item based on the active section
        const menuItem = menu.find(
          (item) => `#${item.href}` === `#${activeSection}`
        );
        setActiveItem(menuItem ? menuItem.label : null);
      } else {
        setActiveItem(null);
      }
    }, // eslint-disable-next-line
    [JSON.stringify(activeSection)]
  );

  const exitDemoUser = () => {
    logout();
    window.location.href = process.env.REACT_APP_PROD_REDIRECT_URL;
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenProductMenu = (event) => {
    setAnchorProductMenu(event.currentTarget);
  };

  const handleCloseUserMenu = (e) => {
    const action = e.target.id;
    // eslint-disable-next-line default-case
    switch (action) {
      case "Login":
        showDialog({
          component: <Login />,
          size: "xs",
          backdropOff: true,
          closeIcon: true
        });
        break;
      case "Logout":
        if (demoLogin) {
          exitDemoUser();
        } else {
          onLogoutClick();
        }
        break;
      case "Profile":
        navigate(appRoutes.profile.main);
        // setCrumbStack(appRoutes.profile.main, profile_labels.personalDetails);
        break;
    }

    setAnchorElUser(null);
  };

  const handleCloseProductMenu = (e) => {
    setAnchorProductMenu(null);
  };

  const openSideDrawer = () => {
    showDrawer({
      component: <Notifications hideDrawer={hideDrawer} />,
      closeIcon: false
    });
  };

  const openCart = () => {
    Boolean(searchFormParams)
      ? showDrawer({
          component: (
            <Cart
              updateTabs={(stepperActiveTab) =>
                handleTabChange(stepperActiveTab)
              }
              onRemove={(type) => onRemoveFromCart(type)}
            />
          ),
          closeIcon: false
        })
      : navigate(appRoutes.explore.main);
  };

  const handleClick = (option) => {
    setActiveItem(option.label);
    if (option.to) {
      // Navigate to the specified page
      option.to === appRoutes.pricing
        ? navigate(option.to)
        : (window.location.href = option.to);
    } else if (option.href) {
      // Check if the user is already on the landing page
      if (window.location.pathname === "/") {
        // Scroll to the corresponding section on the landing page with offset
        scroller.scrollTo(option.href, {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
          offset: -70
        });
      } else {
        // Redirect to landing page with section id as query parameter
        const landingPageUrl = `/?id=${option.href}`;
        navigate(landingPageUrl);
      }
    }
  };

  const LoggedInHeader = (
    <>
      {/* Web view */}
      <>
        {currentUser && (
          <>
            <Grid
              item
              sx={{
                display: {
                  sm: "flex",
                  xs: "none"
                },
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                width: "100%",
                left: 0,
                top: 0,
                height: "100%",
                padding: 0
              }}
            >
              <Typography
                sx={{
                  color: "#2773FF",
                  fontSize: "24px",
                  fontWeight: 700,
                  lineHeight: "33px"
                }}
              >
                {profileData?.user?.client?.companyName?.toUpperCase() ?? ""}
              </Typography>
            </Grid>
            {profileData?.user?.role === "CustomerAdmin" && (
              <>
                <Grid
                  item
                  xs="auto"
                  sx={{
                    display: { xs: "none", sm: "flex" },
                    alignItems: "center",
                    mr: 1
                  }}
                >
                  <Badge
                    badgeContent={countPropertiesWithValues(cart)}
                    color="primary"
                    overlap="circular"
                  >
                    <IconButton onClick={openCart} sx={{ p: 0 }}>
                      <ShoppingCartOutlined />
                    </IconButton>
                  </Badge>
                </Grid>
                <Grid
                  item
                  xs="auto"
                  sx={{
                    display: { xs: "none", sm: "flex" },
                    alignItems: "center",
                    mr: 1
                  }}
                >
                  <Badge
                    badgeContent={data?.pages?.[0]?.unreadCount ?? 0}
                    color="primary"
                    overlap="circular"
                  >
                    <IconButton onClick={openSideDrawer} sx={{ p: 0 }}>
                      <NotificationsOutlined />
                    </IconButton>
                  </Badge>
                </Grid>
              </>
            )}
          </>
        )}
        <Grid
          item
          xs="auto"
          sx={{
            display: { xs: "none", sm: "flex" },
            alignItems: "center"
          }}
        >
          <IconButton
            onClick={handleOpenUserMenu}
            sx={{ p: 0 }}
            aria-controls="menu-appbar"
            aria-haspopup="true"
          >
            <Person />
          </IconButton>
        </Grid>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map((menu) => (
            <MenuItem key={menu} onClick={handleCloseUserMenu}>
              <Typography textAlign="center" id={menu} whiteSpace={"nowrap"}>
                {menu}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </>
      {/* Mobile view */}
      {currentUser ? (
        <Grid
          item
          xs={12}
          container
          sx={{ display: { xs: "flex", sm: "none" } }}
          alignItems={"center"}
          spacing={1}
          px={"5px"}
        >
          <Grid
            item
            xs={1.5}
            display={"flex"}
            alignItems={"center"}
            onClick={() => {
              demoLogin ? exitDemoUser() : navigate("/");
              window.scroll({
                left: 0,
                top: 0,
                behavior: "smooth"
              });
            }}
          >
            <img
              src={MobileAppLogo}
              alt={labels.appTitle}
              loading="lazy"
              width={"40px"}
              height={"40px"}
              style={{
                objectFit: "contain",
                backgroundColor: "transparent"
              }}
            />
          </Grid>
          <Grid
            item
            xs={9}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              sx={{
                color: "#2773FF",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "20px",
                textAlign: "center"
              }}
            >
              {profileData?.user?.client?.companyName?.toUpperCase() ?? ""}
            </Typography>
          </Grid>
          {profileData?.user?.role === "CustomerAdmin" ? (
            <Grid item xs={1.5}>
              <MoreMenu
                openCart={openCart}
                openSideDrawer={openSideDrawer}
                cartCount={countPropertiesWithValues(cart)}
                NotificationCount={data?.pages?.[0]?.unreadCount ?? 0}
                onProfileClick={() => navigate(appRoutes.profile.main)}
                onLogoutClick={() => {
                  if (demoLogin) {
                    exitDemoUser();
                  } else {
                    onLogoutClick();
                  }
                }}
              />
            </Grid>
          ) : (
            <Grid item xs={1.5}>
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ p: 0 }}
                aria-controls="menu-appbar"
                aria-haspopup="true"
              >
                <Person />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            display: { xs: "flex", sm: "none" },
            alignItems: "center",
            justifyContent: "right",
            pr: "16px"
          }}
        >
          <IconButton
            onClick={handleOpenUserMenu}
            sx={{ p: 0 }}
            aria-controls="menu-appbar"
            aria-haspopup="true"
          >
            <Person />
          </IconButton>
        </Grid>
      )}
    </>
  );

  const GlobalHeader = (
    <Grid container spacing={1}>
      <Grid
        item
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent={"space-evenly"}
        sx={{
          display: { xs: "none", md: "flex" }
        }}
        position="sticky"
      >
        <StyledPrimaryText
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            textDecoration: "none",
            whiteSpace: "nowrap"
          }}
          onMouseEnter={handleOpenProductMenu}
          aria-controls="menu-appbar"
          aria-haspopup="true"
        >
          Product{" "}
          {Boolean(anchorProductMenu) ? (
            <ExpandLess
              sx={{
                height: "26px",
                width: "26px"
              }}
              color="text.secondary"
            />
          ) : (
            <ExpandMore
              sx={{
                height: "26px",
                width: "26px"
              }}
              color="text.secondary"
            />
          )}
        </StyledPrimaryText>
        {menu.map((option, index) => (
          <StyledPrimaryText
            key={index}
            variant="body2"
            sx={{
              px: 3,
              textDecoration: "none",
              whiteSpace: "nowrap",
              cursor: "pointer"
            }}
            onClick={() => handleClick(option)}
            isActive={
              location.pathname === option.to ||
              (Boolean(activeSection) && activeItem === option.label)
            }
          >
            {option.label}
          </StyledPrimaryText>
        ))}
        {isValid ? (
          <StyledLoginText
            fontWeight={800}
            component="div"
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={handleOpenUserMenu}
            aria-controls="menu-appbar"
            aria-haspopup="true"
          >
            <IconButton sx={{ mr: 1 }}>
              <Person fontSize="small" />
            </IconButton>
            My Account
          </StyledLoginText>
        ) : (
          <StyledLoginText
            fontWeight={800}
            component="div"
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() =>
              showDialog({
                component: <Login />,
                size: "xs",
                backdropOff: true,
                closeIcon: true
              })
            }
          >
            <Person fontSize="small" sx={{ mr: 1 }} />
            Customer Login
          </StyledLoginText>
        )}
      </Grid>
      <Grid
        item
        xs
        sx={{
          display: {
            xs: "flex",
            md: "none"
          },
          alignItems: "center",
          justifyContent: "right"
        }}
        position="sticky"
      >
        <StyledMobileIconButton
          onClick={handleOpenUserMenu}
          sx={{ p: 0 }}
          aria-controls="menu-appbar"
          aria-haspopup="true"
        >
          <Person />
        </StyledMobileIconButton>
        <StyledMobileIconButton onClick={() => setMobileDrawer(true)}>
          <MenuIcon />
        </StyledMobileIconButton>
        <StyledMobileDrawer open={mobileDrawer}>
          <MobileMenuList
            onClose={() => setMobileDrawer(false)}
            login={() => {
              showDialog({
                component: <Login />,
                size: "xs",
                backdropOff: true,
                closeIcon: true
              });
            }}
            logout={() => {
              if (demoLogin) {
                exitDemoUser();
              } else {
                onLogoutClick();
              }
            }}
            isValid={isValid}
            onMenuClick={handleClick}
          />
        </StyledMobileDrawer>
      </Grid>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        MenuListProps={{
          onMouseLeave: handleCloseUserMenu
        }}
        autoFocus={false}
      >
        {settings.map((menu) => (
          <MenuItem key={menu} onClick={handleCloseUserMenu}>
            <Typography textAlign="center" id={menu} whiteSpace={"nowrap"}>
              {menu}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorProductMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={Boolean(anchorProductMenu)}
        onClose={handleCloseProductMenu}
        MenuListProps={{
          onMouseLeave: handleCloseProductMenu
        }}
        autoFocus={false}
      >
        {productMenu.map((menu, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleClick(menu);
              handleCloseProductMenu();
            }}
            whiteSpace={"nowrap"}
          >
            <StyledPrimaryText
              key={index}
              variant="body2"
              sx={{ textDecoration: "none", whiteSpace: "nowrap" }}
              id={menu.label}
            >
              {menu.label}
            </StyledPrimaryText>
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );

  return (
    <ThemeProvider theme={headerTheme}>
      <Box
        sx={{
          display: {
            xs: "none",
            md: isRoot ? "block" : "none"
          }
        }}
      >
        <DesktopSocialBar />
      </Box>
      <AppBar
        position={isRoot ? "sticky" : "fixed"}
        sx={{
          width: {
            sm:
              !isRoot && currentUser ? `calc(100% - ${drawerWidth}px)` : "100%",
            xs: "100%"
          },
          ml: {
            sm: !isRoot && currentUser ? `${drawerWidth}px` : "auto",
            xs: 0
          },
          boxShadow: isRoot && "none",
          backgroundColor: isRoot && "#F7F8FA",
          border: isRoot && "1px solid #F0F2F5",
          zIndex: 2
        }}
      >
        <StyledToolBar
          component={Container}
          token={!isRoot ? currentUser : undefined}
          sx={
            !isRoot
              ? {
                  padding: {
                    sm: "0 40px",
                    xs: 0
                  }
                }
              : {
                  padding: {
                    sm: "0 90px",
                    xs: "0 16px"
                  }
                }
          }
          maxWidth={!isRoot ? "auto" : "xl"}
        >
          <Box
            sx={{
              flexGrow: { xs: "unset", sm: 1 },
              cursor: "pointer",
              "& > img": {
                display: {
                  xs: !isRoot && currentUser ? "none" : "flex"
                }
              }
            }}
            onClick={() => {
              demoLogin ? exitDemoUser() : navigate("/");
              window.scroll({
                left: 0,
                top: 0,
                behavior: "smooth"
              });
            }}
          >
            <img
              src={AppLogo}
              alt={labels.appTitle}
              height={"40px"}
              width={"160px"}
              loading="lazy"
            />
          </Box>
          {isRoot ? GlobalHeader : LoggedInHeader}
        </StyledToolBar>
      </AppBar>
      <Box
        sx={{
          display: {
            xs: isRoot ? "block" : "none",
            md: "none"
          }
        }}
      >
        <MobileSocialBar />
      </Box>
    </ThemeProvider>
  );
};

export default Header;
