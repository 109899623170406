import * as yup from "yup";

import { Amplify, Auth } from "aws-amplify";
import {
  Box,
  FormHelperText,
  Grid,
  IconButton,
  Link,
  Typography,
  useTheme
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { error_msg, labels } from "../../constants";
import { getProfile, signup } from "../api/searchFormData";
import { useContext, useEffect, useState } from "react";

import { CountdownTimer } from "../utils/CountdownTimer";
import { LoadingButton } from "@mui/lab";
import { MuiOtpInput } from "mui-one-time-password-input";
import { SearchFormKeys } from "../../constants/formKeys";
import Storage from "../utils/Storage";
import { StorageKeys } from "../../constants/storage-keys";
import { StyledButton } from "../formElements/StyledButton";
import TextInput from "../formElements/TextInput";
import { awsExports } from "../../aws-exports";
import { generatePassword } from "../utils";
import { isMobileDevice } from "../../App";
import styled from "@emotion/styled";
import { useCustomDialog } from "../customDialog";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { ContentCopyRounded } from "@mui/icons-material";
import appRoutes from "../../constants/appRoutes";
import { AppContext } from "../context/auth-context";

Amplify.configure(awsExports);

const MuiOtpInputStyled = styled(MuiOtpInput)(({ theme }) => ({
  display: "flex",
  gap: "16px",
  maxWidth: 650,
  marginInline: "auto",
  ".MuiTextField-root input": {
    [theme.breakpoints.up("xs")]: { padding: "8px 0px !important" },
    [theme.breakpoints.up("lg")]: { padding: "12px 16px !important" }
  }
}));

const schema = yup.object({
  [SearchFormKeys.loginMobileNumber]: yup
    .string()
    .required(error_msg.enterMobileNumber)
    .matches(/^[0-9]+$/, error_msg.onlyDigits)
    .length(10, error_msg.enterOnly10Digits)
    .test(
      "is-greater-than-6000000000",
      error_msg.enterLeast10Digits,
      (value) => parseInt(value, 10) > 6000000000
    )
  //[SearchFormKeys.otp]: yup.string().required(error_msg.enterOtp),
});

const typographyStyle = {
  fontSize: "13px",
  letterSpacing: "0em"
};

const h4Style = {
  fontSize: "20px",
  fontWeight: 500,
  lineHeight: "24px",
  letterSpacing: "0.002em",
  textAlign: "left",
  display: "flex"
};

const h5Style = {
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "19px",
  letterSpacing: "0.005em",
  textAlign: "left",
  color: "#607088",
  position: "relative",
  top: "3px"
};

const MobileNumberScreen = ({ control, error, isLoading }) => {
  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12} display={"flex"}>
        <Typography component={"h4"} sx={h4Style}>
          {labels.login}
        </Typography>
        <Typography px={1} component={"h5"} sx={h5Style}>
          or
        </Typography>
        <Typography component={"h4"} sx={h4Style}>
          {labels.signup}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          control={control}
          type="number"
          name={SearchFormKeys.loginMobileNumber}
          placeholder={labels.enterMobileNumber}
        />
      </Grid>
      {!isMobileDevice && (
        <Grid item xs={12}>
          <Typography sx={typographyStyle}>
            {labels.byContinueIAgree}
            <Link href="#" underline="none" px={1}>
              {labels.termsOfUse}
            </Link>
            &
            <Link href="#" underline="none" px={1}>
              {labels.privacyPolicy}
            </Link>
          </Typography>
        </Grid>
      )}
      {isMobileDevice && <Grid item m={8}></Grid>}
      <Grid item xs={12} mt={3}>
        <StyledButton
          fullWidth
          type="submit"
          variant="contained"
          component={LoadingButton}
          loading={isLoading}
        >
          {labels.proceed}
        </StyledButton>
      </Grid>
      <Grid item>
        <FormHelperText error>{error}</FormHelperText>
      </Grid>
      {!isMobileDevice && <Grid item m={8}></Grid>}
      <Grid item xs={12} textAlign={"center"}>
        <Typography sx={typographyStyle}>
          {labels.havingTroubleLoggingIn}
          <Link href="#" underline="none" px={1}>
            {labels.getHelp}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

const OtpConfirmScreen = ({
  clearOtp,
  OTP,
  control,
  error,
  mobileNumber,
  resendOTP,
  isLoading
}) => {
  const theme = useTheme();
  const validateInput = (value, index) => {
    const isNumber = typeof value === "number";
    return (isNumber || value !== "") && !isNaN(Number(value));
  };

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(OTP);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12}>
        <Typography component={"h4"} sx={h4Style}>
          {labels.verifyWithOTP}
        </Typography>
        <Typography sx={typographyStyle}>
          {labels.sentTo} +91 {mobileNumber}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={SearchFormKeys.otp}
          render={({ field, fieldState: { error: fieldError } }) => (
            <Box
              sx={{
                "& .MuiInputBase-root": {
                  height: 44,
                  width: 44,
                  borderWidth: 0,
                  borderRadius: 2,
                  ":hover .Mui-focused": {
                    borderColor: theme.palette.primary.main,
                    borderWidth: 1
                  }
                }
              }}
            >
              <MuiOtpInputStyled
                autoFocus
                sx={{ gap: 1 }}
                {...field}
                length={6}
                id={SearchFormKeys.otp}
                validateChar={validateInput}
                TextFieldsProps={{ inputProps: { inputMode: "numeric" } }}
              />
              {fieldError && (
                <FormHelperText error>{fieldError.message}</FormHelperText>
              )}
            </Box>
          )}
        />
      </Grid>
      <Grid item xs={12} textAlign={"end"}>
        <Link
          sx={{ cursor: "pointer", textTransform: "lowercase" }}
          py={1}
          variant="text"
          size="small"
          onClick={() => clearOtp()}
        >
          {labels.clear}
        </Link>
      </Grid>
      <Grid item xs={12} mt={4} textAlign={isMobileDevice ? "center" : "left"}>
        <Typography sx={typographyStyle}>
          <CountdownTimer
            resendOTP={() => {
              clearOtp();
              resendOTP(mobileNumber);
            }}
          />
        </Typography>
        <Typography sx={{ color: "#2773FF", fontWeight: 600 }}>
          {`OTP : ${OTP}`}{" "}
          <IconButton onClick={handleCopyClick}>
            <ContentCopyRounded sx={{ color: "#2773FF" }} />
          </IconButton>
          {isCopied ? `Copied` : ""}
        </Typography>
      </Grid>
      {isMobileDevice && <Grid item m={8}></Grid>}
      <Grid item xs={12} mt={3}>
        <StyledButton
          fullWidth
          type="submit"
          variant="contained"
          component={LoadingButton}
          loading={isLoading}
        >
          {labels.continue}
        </StyledButton>
      </Grid>
      {error && (
        <Grid item>
          <FormHelperText error>
            {error?.response?.data?.message}
          </FormHelperText>
        </Grid>
      )}
      {!isMobileDevice && <Grid item m={8}></Grid>}
      <Grid item xs={12}>
        <Typography sx={typographyStyle}>
          {labels.havingTroubleLoggingIn}
          <Link href="#" underline="none" px={1}>
            {labels.getHelp}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

const Login = () => {
  const { updateProfileData } = useContext(AppContext);
  const {
    control,
    setValue,
    getValues,
    reset,
    handleSubmit,
    register,
    unregister
  } = useForm({
    defaultValues: {
      [SearchFormKeys.loginMobileNumber]: "",
      [SearchFormKeys.otp]: ""
    },
    resolver: yupResolver(schema)
  });
  const { hideDialog } = useCustomDialog();
  const navigate = useNavigate();
  const location = useLocation();
  const service = Storage.getItem(StorageKeys.serviceLocalStorage);

  const [showOTP, setShowOTP] = useState(false);
  const [OTP, setOTP] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleMobileNumberSubmit = async (mobileNumber, signUp = false) => {
    try {
      setIsLoading(true);
      if (signUp) {
        await Auth.signUp({
          username: mobileNumber,
          password: generatePassword()
        });
        handleMobileNumberSubmit(mobileNumber, false);
      } else {
        const user = await Auth.signIn(mobileNumber);
        setCognitoUser(user);
        console.log("OTP ", user?.challengeParam?.passCode);
        setOTP(user?.challengeParam?.passCode);
        setValue(SearchFormKeys.otp, user?.challengeParam?.passCode);
      }
      setShowOTP(true);
      setIsLoading(false);
    } catch (error) {
      if (
        error.code === "UserNotFoundException" ||
        error.code === "NotAuthorizedException"
      ) {
        handleMobileNumberSubmit(mobileNumber, true);
        return;
      } else if (error.code === "UsernameExistsException") {
        handleMobileNumberSubmit(mobileNumber, false);
        return;
      }
      console.error("Error signing in: ", error);
      setError(error?.message || error_msg.errorSigningMobile);
      setIsLoading(false);
    }
  };

  const handleOTPSubmit = async (otp) => {
    try {
      setIsLoading(true);
      if (otp) {
        await Auth.sendCustomChallengeAnswer(cognitoUser, otp);
        const { signInUserSession } = await Auth.currentAuthenticatedUser();
        // Try to get the profile first
        await getProfile(signInUserSession?.idToken?.jwtToken)
          .then((res) => {
            // If profile fetch is successful, update the profile data
            updateProfileData(res.data);
            if (
              !service &&
              ![appRoutes.explore.listings].includes(location.pathname)
            ) {
              navigate("/dashboard");
            }
            hideDialog();
          })
          .catch(async () => {
            // If getProfile fails, try signing up
            await signup(signInUserSession?.idToken?.jwtToken)
              .then(async (res) => {
                // Call getProfile after signup succeeds
                const profileRes = await getProfile(
                  signInUserSession?.idToken?.jwtToken
                );
                // Update profile data after getting the profile post-signup
                updateProfileData(profileRes.data);
                // Check conditions and navigate if necessary
                if (
                  !service &&
                  ![appRoutes.explore.listings].includes(location.pathname)
                ) {
                  navigate("/dashboard");
                }
                hideDialog();
              })
              .catch((signupError) => {
                // If signup fails, log the error
                console.error("Error signing in: ", signupError);
                Auth.signOut();
                setError(error || error_msg.errorSigningOTP);
                setIsLoading(false);
              });
          });
      }
    } catch (error) {
      console.error("Error signing in: ", error);
      Auth.signOut();
      setError(error || error_msg.errorSigningOTP);
      setIsLoading(false);
    }
  };

  const formHandler = (data) => {
    showOTP
      ? handleOTPSubmit(data[SearchFormKeys.otp])
      : handleMobileNumberSubmit(data[SearchFormKeys.loginMobileNumber], true);
  };

  useEffect(() => {
    if (showOTP) {
      register(SearchFormKeys.otp);
    } else {
      register(SearchFormKeys.loginMobileNumber);
      unregister(SearchFormKeys.otp);
    }
  }, [register, unregister, showOTP]);

  return (
    <>
      <Box component={"form"} onSubmit={handleSubmit(formHandler)}>
        {showOTP ? (
          <OtpConfirmScreen
            clearOtp={() =>
              reset((formValues) => ({
                ...formValues,
                [SearchFormKeys.otp]: ""
              }))
            }
            OTP={OTP}
            control={control}
            error={error}
            mobileNumber={getValues(SearchFormKeys.loginMobileNumber)}
            resendOTP={handleMobileNumberSubmit}
            isLoading={isLoading}
          />
        ) : (
          <MobileNumberScreen
            control={control}
            error={error}
            isLoading={isLoading}
          />
        )}
      </Box>
    </>
  );
};

export default Login;
