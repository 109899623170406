import { cartServiceKeys } from "../../constants/formKeys";
import { createContext } from "react";

export const AppContext = createContext({
  // isLoggedIn: false,
  // token: null,
  // idToken: null,
  // login: () => {},
  profileData: null, // state variable to store profile data
  demoLogin: false,
  updateProfileData: () => {},
  logout: () => {},
  // setLoginToken: () => {},
  crumbsStack: new Map(),
  setCrumbStack: () => {},
  removeCrumbStack: () => {},
  currentUser: null,
  cart: {
    [cartServiceKeys.warehouse]: null,
    [cartServiceKeys.transport]: null
  },
  onAddToCart: () => {},
  onRemoveFromCart: () => {},
  activeTab: 0,
  handleTabChange: () => {},
  activeMobileTab: {},
  handleMobileTabChange: () => {},
  isSwitchChecked: false,
  handleSwitchToggle: () => {}
});
